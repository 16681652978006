document.addEventListener('DOMContentLoaded', function () {
  document.querySelectorAll('.sensitive-data').forEach(function (element) {
    var data = element.textContent.trim();
    element.innerHTML = '<em>Click to view</em>';
    element.style.display = 'block';
    element.addEventListener(
      'click',
      function () {
        this.textContent = data;
      },
      { once: true }
    );
  });
});
