import { uploadFilesSigned } from './file-upload-signed';

function stopPropagation(e) {
  e.stopPropagation();
  e.preventDefault();
}

const dragDropAttributeName = 'data-file-upload-by-drag-drop-form';
const signedDragDropAttributeName = 'data-file-upload-by-drag-drop-form-signed';
const formNodeSelector = `[${dragDropAttributeName}], [${signedDragDropAttributeName}]`;

export const initFileUploadByDragDrop = () => {
  const formNodes = document.querySelectorAll(formNodeSelector);
  if (formNodes.length === 0) {
    return;
  }

  if (formNodes.length > 1) {
    throw new Error(
      `More than one form per page is not supported by drag and drop upload. Found ${formNodes.length} forms on this page.`
    );
  }

  const formNode = formNodes[0];
  const isSignedUpload = formNode.hasAttribute(signedDragDropAttributeName);

  document.addEventListener('dragenter', stopPropagation, false);
  document.addEventListener('dragover', stopPropagation, false);

  function handleFiles(e) {
    stopPropagation(e);
    const { files } = e.dataTransfer;

    if (!files.length) {
      return;
    }

    const inputNodes = formNode.querySelectorAll('input[type=file]');
    if (inputNodes.length !== 1) {
      throw new Error(
        `File upload by drag and drop requires exactly one file input node, got ${inputNodes.length}.`
      );
    }
    const inputNode = inputNodes[0];
    inputNode.files = files;
    if (isSignedUpload) {
      uploadFilesSigned(formNode);
    } else {
      formNode.submit();
    }
  }

  document.addEventListener('drop', handleFiles);
};
