import 'htmx.org'; // initializes htmx
import moment from 'moment';

import '../styles/main.scss';
import { getHTML, getJSON, postForm, postJSON } from './fetch-wrapper.ts';
import { initFileUploadByDragDrop } from './file-upload-by-drag-drop';
import initForms from './forms.js';
import './htmx/index.js'; // set htmx on window and load extensions
import './one-submit-forms';
import './polyfills.ts';
import './sensitive-data-toggler';
import { initSentry } from './sentry';
initSentry();

window.cloud = window.cloud || {};
Object.assign(window.cloud, {
  getJSON,
  getHTML,
  postForm,
  postJSON,
});

function stopPanelCollapse(elem) {
  elem.addEventListener('click', (e) => {
    e.stopPropagation();
  });
}

window.addEventListener('DOMContentLoaded', async () => {
  window.moment = moment;
  document
    .querySelectorAll('[data-toggle="collapse"] a')
    .forEach(stopPanelCollapse);
  initFileUploadByDragDrop();
  initForms();

  if (localStorage.getItem('CLOUD_DISABLE_AUTOFOCUS')) {
    // Intended for developers to disable autofocus on the search field thats
    // part of section_base.html
    document
      .querySelectorAll('input[autofocus][name="query"]')
      .forEach((element) => {
        element.blur();
      });
  }
});
