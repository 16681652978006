/**
 * This file is used to load the htmx library, assign it to window and load its
 * extensions.
 */

import htmx from 'htmx.org/dist/htmx.esm';
import morphdom from 'morphdom';

htmx.config.selfRequestsOnly = false;
window.htmx = htmx;

// Set up the morphdom-swap extension.
// https://htmx.org/extensions/morphdom-swap/
htmx.defineExtension('morphdom-swap', {
  isInlineSwap: function (swapStyle) {
    return swapStyle === 'morphdom';
  },
  handleSwap: function (swapStyle, target, fragment) {
    if (swapStyle === 'morphdom') {
      if (fragment.nodeType === Node.DOCUMENT_FRAGMENT_NODE) {
        // IE11 doesn't support DocumentFragment.firstElementChild
        morphdom(target, fragment.firstElementChild || fragment.firstChild);
        return [target];
      } else {
        morphdom(target, fragment.outerHTML);
        return [target];
      }
    }
  },
});
