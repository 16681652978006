document.addEventListener('DOMContentLoaded', function () {
  document.querySelectorAll('form.one-submit').forEach(function (form) {
    form.addEventListener('submit', function (e) {
      if (this.getAttribute('has-submitted')) {
        e.preventDefault();
        return;
      }
      this.setAttribute('has-submitted', 'true');
      this.querySelectorAll('[type="submit"]').forEach(function (submitButton) {
        submitButton.classList.add('disabled');
      });
    });
  });
});
