import { cloudFetch } from "./fetch-wrapper";

export const uploadFilesSigned = async (form) => {
  const api = form.action;
  const files = getFormFiles(form);

  const { requests: signedRequests } = await getSignedRequests(api, files);
  const objectIDs = await uploadFiles(signedRequests, files);
  await confirmUploads(api, objectIDs);

  window.location.reload();
};

const getFormFiles = (form) => {
  const formData = new FormData(form);

  return [...formData]
    .filter(([, fieldValue]) => fieldValue instanceof File)
    .reduce((acc, [, file]) => {
      acc[file.name] = file;
      return acc;
    }, {});
};

const getSignedRequests = async (api, files) => {
  const response = await window.cloud.postJSON(api, {
    body: { object_names: Object.keys(files) },
  });

  return response.json();
};

const uploadFiles = (signedRequests, files) => {
  const uploadRequests = signedRequests.map(({ url, fields, extra }) => {
    const uploadForm = new FormData();
    const formFields = { ...fields, file: files[extra.client_object_name] };
    Object.entries(formFields).map(([key, value]) =>
      uploadForm.append(key, value)
    );

    return fetch(url, {
      method: 'POST',
      body: uploadForm,
    });
  });

  return Promise.all(uploadRequests).then((responses) => {
    const uploadedObjectIDs = [];
    signedRequests.map((req, idx) => {
      const response = responses[idx];
      if (response.ok) {
        uploadedObjectIDs.push(req.extra.server_object_id);
      }
    });

    return uploadedObjectIDs;
  });
};

const confirmUploads = (api, uploadedObjectIDs) => {
  return cloudFetch(api, {
    method: 'PATCH',
    body: JSON.stringify({ object_ids: uploadedObjectIDs }),
  });
};
