import { initAsyncForm } from '../asyncform/async-form-helper'
import { POST_AS_JSON, parse as parsepostType } from '../asyncform/post-types';
import handleFileUploads from './handle-file-uploads';
import { persistSuccessHandler } from '../asyncform/persist-error-handler';

export default () => {
  handleFileUploads();

  document
    .querySelectorAll(
      '[data-async-form]'
    )
    .forEach(form => {
      const postType = parsepostType(
        form.getAttribute('data-async-post-type') || POST_AS_JSON,
      );

      initAsyncForm({
        form,
        postType,
        successHandler: persistSuccessHandler,
      });
    });

  document
    .querySelectorAll('[data-checkbox-wrapper]')
    .forEach(checkboxWrapper => {
      function handleCheckboxChange(e) {
        checkboxWrapper.classList[e.target.checked ? 'add' : 'remove'](
          'checked',
        );
      }
      checkboxWrapper
        .querySelector('[type="checkbox"]')
        .addEventListener('change', handleCheckboxChange);
    });
};
