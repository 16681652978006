import { uploadFilesSigned } from './file-upload-signed';

export default () => {
  initFileAutoSubmitForms();
  initSignedFileUploadForms();
};

/*
  Just mark your form with 'data-file-autosubmit-form' to enable this.

  PS: Does not work with data-project-detail-async-form.
  Split into two forms if you need primitve async fields as well
*/
const initFileAutoSubmitForms = () => {
  const autoSubmitForms = document.querySelectorAll(
    '[data-file-autosubmit-form]'
  );

  autoSubmitForms.forEach((form) => {
    const fileInputElemAll = form.querySelectorAll('[type="file"]');
    fileInputElemAll.forEach((fileInputElem) => {
      fileInputElem.addEventListener('change', () => {
        const label = fileInputElem.parentElement;
        const spinner = label.querySelector('[data-spinner]');
        spinner.classList.remove('d-none');
        form.submit();
        fileInputElem.setAttribute('disabled', 'disabled');
      });
    });
  });
};

/*
  Mark forms with 'data-file-upload-signed' to enable uploads using presigned
  URLs. Each form is limited to a single file input field.

  This assumes that the backend is set up to return signed request data,
  and to receive confirmed uploads.
*/
export const initSignedFileUploadForms = () => {
  const signedFileUploadForms = document.querySelectorAll(
    '[data-file-upload-signed]'
  );

  signedFileUploadForms.forEach((form) => {
    const fileInput = form.querySelectorAll('[type="file"]');
    if (fileInput.length !== 1) {
      throw new Error(
        `Signed file upload requires exactly one input node, got ${fileInput.length}.`
      );
    }

    fileInput[0].addEventListener('change', (e) => {
      e.preventDefault();
      e.stopPropagation();
      uploadFilesSigned(form);
    });
  });
};
